/* Montserrat Font */
@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400; 
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500; 
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600; 
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400; 
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500; 
    font-style: normal;
  }