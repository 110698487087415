.hero-text {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 61px;

  color: #2d2d2d;
}

.hero-btn {
    border-radius: 33px;
    width: 16rem;
    font-size: 24px;
}

.hero-p {
    font-size: 24px;
}


