.footer-container {
    height: 21.5rem;
    background-color: #242424;
    object-fit: scale-down;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 24px;
}

.ct-logo-footer {
    height: 4rem;
}

.footer-item {
    cursor: pointer;
    color:white;
    font-size:24px;
}

.footer-item:hover,
.footer-item:active {
    cursor: pointer;
    color:#f45c43
}

.footer-text {
    color: white;
    font-size: 24px;
}

.socials {
    /* border: 2px solid red; */
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-after {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer-address {
    font-size: 16px;
    line-height: 24px;
}

.footer-address {
    margin: 4px;
}

.footer-copyrights {
    font-size: 8px;
    line-height: 24px;
}