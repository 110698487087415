.qa-head {
    font-size: 45px;
    line-height: 64px;
}

.qa-text {
    font-size: 24px;
    line-height: 40px;
}

.qa-card-id {
    font-size: 60px;
    line-height: 64px;
    position: absolute;
}

.qa-card-text {
    font-size: 16px;
    line-height: 24px;
}

.qa-container {
    display: flex;
    flex-direction: row;
}
