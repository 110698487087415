.showcase-head {
    width: 100%;
    text-align: center;
    font-size: 64px;
    line-height: 64px;
}

.showcase-grid-container {
    position: relative;
    margin: 5rem 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.showcase-grid {
    width: 60%;
    height: 648px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 16px;
}

.showcase-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
}

.showcase-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.showcase-item-1 {
    grid-column: span 2;
    grid-row:span 2;
}

.showcase-item-7,
.showcase-item-4 {
    grid-column: span 2;
}

.showcase-item-7 {
    grid-row: span 1;
}

.showcase-item-5,
.showcase-item-6,
.showcase-item-4 {
    grid-row: span 2;
}

.showcase-item:hover .showcase-img {
    filter: blur(1px);
    transform: scale(1.01);
}

.modal-overlay {
    position: fixed;
    top: 25vh;
    left:10vw;
    width: 80vw;
    height: 60vh;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    outline: none;
}

.model-desc {
    align-self: start;
    width: 80%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.8;
}

.modal-img {
    width: min-content;
    max-width: 40%;
    max-height: 50vh;
    object-fit: scale-down;
    border-radius: 8px;
    margin-bottom: 10px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: red;
}

.modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    margin-top: 1vh;
    padding: 8px 16px;
    background: linear-gradient(90deg, #f45c43 0%, #eb3349 100%);
    border-radius: 33px;
    display: flex;
    align-items: center;
    justify-content: between;
    width: 15vw;
}
