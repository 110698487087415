.aboutUs-head {
    font-size: 64px; /* Adjusted for responsiveness */
    line-height: 1.2;
    text-align: center;
    margin-bottom: 3rem; /* Reduced spacing for better layout */
    font-weight: bold;
}

.aboutUs-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
    justify-content: space-between;
    gap: 2rem; /* Added gap for spacing */
    margin: 5rem 0px;
}

.aboutUs-container-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%; /* Default for smaller screens */
    max-width: 45%; /* Restricts width for larger screens */
    box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
    padding: 1.5rem; /* Reduced padding for cleaner look */
    border-radius: 1rem;
    background-color: #fff; /* Ensures a clean background */
    flex-grow: 1;
}

.aboutUs-left-head {
    font-size: 2.5rem; /* Adjusted for responsiveness */
    line-height: 1.4;
    margin-bottom: 1rem;
}

.aboutUs-left-text {
    font-size: 1.2rem; /* Relative font sizing for flexibility */
    line-height: 1.8;
    color: #636363;
    margin-bottom: 2rem;
}

.aboutUs-left-btn {
    border: 2px solid #eb3349;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    color: #eb3349;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Added hover transition */
}

.aboutUs-left-btn:hover {
    background-color: #eb3349;
    color: #fff;
}

.aboutUs-container-right {
    width: 100%; /* Default for smaller screens */
    max-width: 50%; /* Restricts width for larger screens */
    display: flex;
    flex-direction: column;
    gap: 2rem; /* Adds spacing between right content */
    flex-grow: 1;
}

.aboutUs-right-up {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.aboutUs-right-up img {
    width: 100%;
    border-radius: 1rem; /* Makes the image visually appealing */
    object-fit: cover;
}

.aboutUs-right-down {
    display: flex;
    flex-wrap: wrap; /* Allows cards to wrap on smaller screens */
    gap: 1rem; /* Ensures consistent spacing between cards */
}

.aboutUS-items {
    text-align: left;
    flex: 1 1 calc(50% - 1rem); /* Responsive cards with consistent spacing */
    border-radius: 1rem;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.1); /* Softer shadow */
    overflow: hidden;
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s; /* Hover effect */
}

.aboutUs-items-value {
    font-size: 45px;
    line-height: 64px;
}

.aboutUs-items-desc {
    font-size: 18px;
    line-height: 40px;
}


