.pros-head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}


.pros-head {
    line-height: 61px;
}

.pros-text {
    margin-top: 1rem;
    width: 40%;
    color: #636363;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
}

.pros-cards-container {
    margin: 5rem auto;
    align-self: center;
    justify-self:center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 48px;
  }
  
  .pros-card {
    flex: 1 1 calc(28.33% - 16px); 
    max-width: calc(28.33% - 16px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #e5e7eb; /* Light border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 32px 48px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    line-height: 22px;
    color: #eb3349;
  }
  
  .pros-card:hover {
    transform: translateY(-4px); /* Hover effect */
    background: linear-gradient(90deg, #f45c43, #eb3349);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: white;
  }
  
  .pros-card-img {
    object-fit: cover;
    margin-bottom: 16px;
  }
  
  .pros-card-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
  }
  
  .pros-card-text {
    font-size: 16px;
    text-align: center;
  }


  

