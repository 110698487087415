/* .navbar {
     border: 2px solid red;
} */

.login-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  background: linear-gradient(90deg, #f45c43 0%, #eb3349 100%);
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: between;
  width: 100%;
}

.login-btn img {
    height: 1rem;
    margin-right: 8px;
}

.ct-logo {
    height: 4rem;
    object-fit: contain;
}

.ct-logo img {
    height: 100%;
}

.nav-item {
    font-size: 20px;
}

.nav-item:hover,
.nav-item:active {
    cursor: pointer;
    color:#f45c43
}
